<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>アカウント</el-breadcrumb-item>
      <el-breadcrumb-item>{{$t('addGroup.addRole')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <h2>{{ accountInfo.groupCode }}</h2>
    <el-card class="box-card" shadow="never">
      <div slot="header" class="clearfix">
        <span><i class="el-icon-coin"></i>&nbsp;{{$t('addGroup.role')}}</span>
      </div>
      <el-form ref="form" :model="form" label-width="150px" :rules="rules">
        <el-form-item label="コード" prop="groupCode">
          <el-input v-model="form.groupCode" maxlength="20" style="width: 70%;"></el-input>
        </el-form-item>
        <el-form-item label="グループ名" prop="groupName">
          <el-input v-model="form.groupName" maxlength="50" style="width: 70%;"></el-input>
        </el-form-item>
        <el-form-item label="BIMSの請求先コード" prop="bimsBlcbid">
          <el-input v-model="form.bimsBlcbid" maxlength="3" style="width: 70%;"></el-input>
        </el-form-item>
        <el-form-item label="DIPFS用二次店名">
          <el-input v-model="form.scndAgencyNm" style="width: 70%;"></el-input>
        </el-form-item>
        <el-form-item label="PP担当者">
          <el-select v-model="form.ppCbsic1" placeholder="キーワードを入力してください。" filterable remote :remote-method="queryMemberList" clearable style="width: 70%;">
            <el-option
              v-for="item in memberList"
              v-bind:key="item.kbCode"
              v-bind:label="item.kbName"
              v-bind:value="item.kbCode">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="特定メニューの表示" v-if="accountInfo.role <= 3">
          <el-radio v-model="form.showMenuFlg" :label=0>非表示</el-radio>
          <el-radio v-model="form.showMenuFlg" :label=1>表示</el-radio>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" round @click="submitForm('form')">{{$t('addGroup.add')}}</el-button>
          <el-button round @click="resetForm()">{{$t('addGroup.back')}}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { addGroup } from "@/api/admin";
import { getAllMemberList} from "@/api/customer";
import { mapState } from "vuex";
import { ROUTER_PATH } from "@/constants/common.js";
export default {
  name: "AddRole",
  data() {
    return {
      backupInfo: {},
      memberList: [],
      form: {
        groupCode: "",
        groupName: "",
        bimsBlcbid: "",
        scndAgencyNm: "",
        ppCbsic1: null,
        showMenuFlg: 1,
        processId: []
      },
      rules: {
        groupCode: [
          { required: true, message: "コードを入力してください。", trigger: "blur" }
        ],
        groupName: [
          { required: true, message: "グループ名を入力してください。", trigger: "blur" }
        ],
        bimsBlcbid: [
          { pattern: /^[0-9]{3}$/, message: "数字3桁で入力してください。", trigger: "blur" }
        ]
      }
    };
  },
  mounted() {
    this.queryMemberList();
  },
  computed: {
    ...mapState(["accountInfo"])
  },
  methods: {
    queryMemberList(keyword) {
      getAllMemberList({
        keyword: keyword
      }).then(response => {
        this.memberList = response.data;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          addGroup({
            ...this.form
          }).then(response => {
            this.$message({
              message: response.message,
              type: "success"
            });
            //this.refreshData();
            this.$router.push(ROUTER_PATH.ADMIN_GROUP);
          });
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.$router.push(ROUTER_PATH.ADMIN_GROUP);
    }
  }
};
</script>
